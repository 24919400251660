body {
  margin: 0;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Kanit';
  src: url('./assets/fonts/Kanit/Kanit-Regular.ttf');
}