body::-webkit-scrollbar {
  width: 12px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #5c789f;
  box-shadow: inset 0 0 5px grey; 
  border-radius: 4px;
  
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #0d244f;
  border-radius: 4px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}